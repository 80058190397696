(function($) {
    doc.on("click", ".part_ui_select", function(){
        $(this).toggleClass("state--focus");
        $(this).one("blur",function() {
            $(this).removeClass("state--focus");
        });
    });

    doc.on("change", ".part_ui_select select", function(){
        if ($(this)[0].value === "") {
            $(this).parent().addClass("state--placeholder");
        } else {
            $(this).parent().removeClass("state--placeholder");
        }
    });

    $(".part_ui_select[tabindex]").each(function () {
        const element = $(this)[0]
        const select = element.querySelector('select')
        const option = element.querySelectorAll('[data-option]')

        if (option[0] !== null) {
            option.forEach(option => {
                option.addEventListener('click', () => {
                    select.value = option.getAttribute('data-option')
                    select.dispatchEvent(new Event('change', { bubbles: true }))
                })
            })
        }
    })
})(jQuery);
